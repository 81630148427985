import React from "react";

import HeroContainer from "../../components/HeroContainer/HeroContainer";
import MembershipContainer from "../../components/MembershipContainer/MembershipContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import BookingContainer from "../../components/BookingContainer/BookingContainer"
import Contact from "../../components/Contact/Contact";
import LocalisationContainer from "../../components/LocalisationContainer/LocalisationContainer";
import Fade from "react-reveal/Fade.js";
import Testimonials from "../../components/Testimonials/testimonials";

const HomePage = () => {
  return (
    <>
      <Fade bottom>
        <HeroContainer/>
        <MembershipContainer />
      </Fade>
      <AboutContainer />
      <BookingContainer />
      <Fade bottom>
        <Contact />
        <LocalisationContainer/>
      </Fade>
      <Testimonials/>
    </>
  );
};

export default HomePage;
