import React from 'react'
import "./Terms.css"
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Terms = () => {

  const { t } = useTranslation();

  return (
    <>
    <div className='terms__container'>
      <div className='terms__header'>
      <h1>{t("terms-title")}</h1>
      </div>
      <div className='terms__content'>
      <p>
      {t("terms-1")}
    <br/>
    {t("terms-2")}
    <br/>
    {t("terms-3")}
    <br/>
    {t("terms-4")}<br/>
<br/>
{t("terms-5")}<br/><br/>
{t("terms-6")}<br/>
{t("terms-7")}<br/>
{t("terms-8")}<br/>
{t("terms-9")}
<br/>
{t("terms-10")}<br/><br/>
{t("terms-11")}<br/><br/>
{t("terms-12")}<br/>
{t("terms-13")}<br/>
{t("terms-14")}
<br/>
{t("terms-15")}<br/><br/>
{t("terms-16")}<br/><br/>
{t("terms-17")}<br/>
{t("terms-18")}<br/><br/>
{t("terms-19")}<br/><br/>
{t("terms-20")}<br/>
{t("terms-21")}<br/>
{t("terms-22")}<br/><br/>
{t("terms-23")}<br/><br/>
{t("terms-24")}<br/>
{t("terms-25")}<br/>
{t("terms-26")}<br/><br/>
{t("terms-27")}<br/><br/>
{t("terms-28")}
<br/>
{t("terms-29")}<br/><br/>
{t("terms-30")}<br/><br/>
{t("terms-31")}<br/>
{t("terms-32")}<br/>
{t("terms-33")}<br/>
{t("terms-34")}<br/><br/>
{t("terms-35")}<br/><br/>
{t("terms-36")}<br/>
{t("terms-37")}<br/>
{t("terms-38")}<br/><br/>
{t("terms-39")}<br/><br/>
{t("terms-40")}<br/><br/>
{t("terms-41")}<br/><br/>
{t("terms-42")}<br/><br/>
{t("terms-43")}<br/><br/>
{t("terms-44")}<br/>
{t("terms-45")}<br/><br/>
{t("terms-46")}<br/><br/>
{t("terms-47")}<br/>
{t("terms-48")}<br/>
{t("terms-49")}<br/>
{t("terms-50")}<br/>
{t("terms-51")}<br/>
{t("terms-52")}<br/>
{t("terms-53")}<br/>
{t("terms-54")}<br/>
{t("terms-55")}<br/>
{t("terms-56")}<br/>
{t("terms-57")}<br/>
{t("terms-58")}<br/><br/>
{t("terms-59")}<br/><br/>
{t("terms-60")}<br/>
{t("terms-61")}<br/>
{t("terms-62")}<br/><br/>
{t("terms-63")}<br/><br/>
{t("terms-64")}<br/><br/>
{t("terms-65")}<br/><br/>
{t("terms-66")}<br/><br/>
{t("terms-67")}<br/><br/>
{t("terms-68")}<br/>
{t("terms-69")}<br/><br/>
{t("terms-70")}<br/><br/>
{t("terms-71")}<br/>
{t("terms-72")}<br/><br/>
{t("terms-73")}<br/><br/>
{t("terms-74")}<br/><br/>
{t("terms-75")}<br/><br/>
{t("terms-76")}<br/>
{t("terms-77")}<br/><br/>
{t("terms-78")}<br/><br/>
{t("terms-79")}<br/>

    </p>
    <NavLink className="return-btn" to="/">HOME PAGE</NavLink>
      </div>

    </div>
    
    
    </>
  )
}

export default Terms