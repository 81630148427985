import React from "react";
import "./MembershipContainer.css";

import { useTranslation } from "react-i18next";

const MembershipContainer = () => {
  const { t } = useTranslation();

  return (
    <div
      className="membershipContainer flex__center section__padding"
      id="membership"
    >
      <h1>{t("membership-title")}</h1>
      <ul className="membershipContainer-content">
        <li>
          <h3>{t("products-1")}</h3>
          <p>${t("products-1-price") / 100}.00 CAD*</p>
          <a
            href="https://kulturealthletique.fliipapp.com/home/login"
            target="blank"
          >
            {t("membership-btn")}
          </a>
        </li>
        <li>
          <h3>{t("products-2")}</h3>
          <p>${t("products-2-price") / 100}.00 CAD*</p>
          <a
            href="https://kulturealthletique.fliipapp.com/home/login"
            target="blank"
          >
            {t("membership-btn")}
          </a>
        </li>
        <li>
          <h3>{t("products-3")}</h3>
          <p>${t("products-3-price") / 100}.00 CAD*</p>
          <a
            href="https://kulturealthletique.fliipapp.com/home/login"
            target="blank"
          >
            {t("membership-btn")}
          </a>
        </li>
        <li>
          <h3>{t("products-4")}</h3>
          <p>${t("products-4-price") / 100}.00 CAD*</p>
          <a
            href="https://kulturealthletique.fliipapp.com/home/login"
            target="blank"
          >
            {t("membership-btn")}
          </a>
        </li>
        <div className="informations">
          <h4>*{t("membership-information")}</h4>
        </div>
      </ul>
      <hr></hr>
    </div>
  );
};

export default MembershipContainer;
