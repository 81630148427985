import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaWindowClose } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import LOGO from "../../assets/Images/logo2.png"

import { useTranslation } from "react-i18next";

import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false); 

  const changeLanguage = (event) => {
    setIsLoading(true);
    setTimeout(() => {
      i18n.changeLanguage(event.target.value);
      setIsLoading(false);
    }, 1000);
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 500); // Ajoutez un délai de 500 millisecondes (0.5 secondes) avant de commencer le défilement
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <nav className={colorChange ? "app__navbar colorChange" : "app__navbar"}>
        <div className="app__navbar-logo">
          <NavLink to="/" onClick={scrollToTop} style={{ cursor: "pointer" }}>
            <img src={LOGO} alt="logo" />
          </NavLink>
        </div>
        {window.location.pathname === "/" && (
          <ul className="app__navbar-links">
            <li>
              <Link
                to="booking"
                smooth={true}
                activeClass="active"
                className="app__navbar-link"
              >
                {t("navbar-link-1")}
              </Link>
            </li>
            <li>
              <Link
                to="membership"
                smooth={true}
                activeClass="active"
                className="app__navbar-link"
              >
                {t("navbar-link-2")}
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth={true}
                activeClass="active"
                className="app__navbar-link"
              >
                {t("navbar-link-3")}
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                smooth={true}
                activeClass="active"
                className="app__navbar-link"
              >
                {t("navbar-link-4")}
              </Link>
            </li>
          </ul>
        ) }
        <div className="language__container">
          <button
            className="language__container-btn"
            onClick={changeLanguage}
            value="en"
            name="language"
            defaultChecked
          >
            EN
          </button>

          <button
            className="language__container-btn"
            onClick={changeLanguage}
            value="fr"
            name="language"
          >
            FR
          </button>
        </div>
        <div className="app__navbar-smallscreen">
          <GiHamburgerMenu
            color="#3792cb"
            fontSize={27}
            onClick={() => setToggleMenu(true)}
          />

          {toggleMenu && (
            <div className="app__navbar-smallscreen_overlay slide-bottom">
              <FaWindowClose
                
                fontSize={27}
                className="overlay_close"
                onClick={() => setToggleMenu(false)}
              />
              <ul className="app__navbar-smallscreen-links">
                <li>
                  <a href="#booking" onClick={() => setToggleMenu(false)}>
                    {t("navbar-link-1")}
                  </a>
                </li>
                <li>
                  <a href="#membership" onClick={() => setToggleMenu(false)}>
                    {t("navbar-link-2")}
                  </a>
                </li>
                <li>
                  <a href="#about" onClick={() => setToggleMenu(false)}>
                    {t("navbar-link-3")}
                  </a>{" "}
                </li>
                <li>
                  <a href="#contact" onClick={() => setToggleMenu(false)}>
                    {t("navbar-link-4")}
                  </a>
                </li>
              </ul>
              <div className="language__container-mobile">
                <button
                  className="language__container-btn-mobile"
                  onClick={changeLanguage}
                  value="en"
                  name="language"
                  defaultChecked
                >
                  EN
                </button>

                <button
                  className="language__container-btn-mobile"
                  onClick={changeLanguage}
                  value="fr"
                  name="language"
                >
                  FR
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
