import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/Images/guy.png";
import AVTR2 from "../../assets/Images/jeremy.png";
import AVTR3 from "../../assets/Images/laurencesamuelbeaulieu.png";
import AVTR4 from "../../assets/Images/bastien.png";
import AVTR5 from "../../assets/Images/maksim.png";
import AVTR6 from "../../assets/Images/laurent.png";
import AVTR7 from "../../assets/Images/n28skin.png";
import AVTR8 from "../../assets/Images/eugene.png";
import Rating from "../../assets/Images/ratings.png";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";


const data = [
  {
    avatar: AVTR1,
    name: "Guy",
    review:
      "Un studio proche de sa clientèle, qui sait s'adapter et qui nous pousse à toujours se dépasser. Samy et Thomas sont des coachs humain, disponible qui prennent soin de nous ! ",
  },
  {
    avatar: AVTR2,
    name: "Jeremy",
    review:
      "Je fréquente ce studio d'entraînement depuis l'ouverture. L'ambiance y est chaleureuse et accueillante,  Samy et Thomas sont compétents et passionnés. En tant que boxeur, j'ai pu évoluer techniquement de manière significative, en apprenant de nouvelles techniques et en perfectionnant celles que je connaissais déjà. Les équipements sont également d'excellente qualité et toujours bien entretenus, ce qui rend les entraînements encore plus agréables. Je recommande ce studio à tous ceux qui cherchent à progresser au niveau de la boxe mais aussi de l'entraînement physique.",
  },
  {
    avatar: AVTR3,
    name: "Laurence",
    review:
      "Kulture Athletics m'a été référé lorsque je cherchais à perfectionner ma technique et commencer à augmenter le rythme de mon entrainement. Je n'aurais pas pu mieux tomber, Samy et Thomas sont des coachs attentifs et sérieux avec énormément d'imagination et de différentes techniques pour accomplir nos objectifs! I just can't get enough of those amazing sessions!",
  },
  {
    avatar: AVTR4,
    name: "Bastien",
    review:
      "Super endroit pour débuter (et/ou continuer!) la boxe ainsi que pour se remettre en forme! Thomas et Samy sont à l'écoute et offrent des cours diversifiés, adaptés et des conseils personnalisés dans un studio à taille humaine.",
  },
  {
    avatar: AVTR5,
    name: "Maksim",
    review:
      "Vous envisagez découvrir la boxe pour vous dépasser et cultiver votre forme physique? Vous avez de l’expérience en boxe et êtes à la recherche d’un endroit pour passer à un autre niveau? Ce gym de quartier est l’endroit parfait! Les coachs Thomas et Samy cultivent cet endroit avec amour et passion pour la boxe et l’entraînement physique. Expérimentés et à l’écoute de nos besoins, ils savent rendent chaque entraînement personnalisé dans une ambiance semi-privée ou privée. C’est décontracté, on rit beaucoup et l’attitude générale est au dépassement de soi et à aux saines habitudes! Venez l’essayer!",
  },
  {
    avatar: AVTR6,
    name: "Laurent",
    review:
      "Enfin un studio où les propriétaires ont à coeur leurs clients! Professionnels, courtois, à l'écoute de vos besoins!",
  },
  {
    avatar: AVTR7,
    name: "N28 Skin",
    review:
      "Les entraînements offerts par les coachs de cet établissement sont tous simplement incroyable. Je me suis inscrite il y’a trois semaine et je vois déjà une différence sur ma shape. Ce que j’aime le plus ce sont les étoiles qui s’illumine dans les yeux de ma coach quand elle nous explique le fameux (EMOM). Si vous cherchez une place avec une équipe énergique,souriant(e) à l’écoute de vos besoin et qui s’assure que quand vous faites les mouvements que vous les faites bien pour éviter que vous vous blesser kulture athletics est l’endroit qu’il vous faut.",
  },
  {
    avatar: AVTR8,
    name: "Eugene",
    review:
      "Sans aucun doute l'un des meilleurs entraîneurs de boxe à Montréal !",
  },
];

const Testimonials = () => {


  const { t } = useTranslation();

  return (
    <section id="testimonials">
      <div className="testimonial_header">
        <h1>{t("testimonial")}</h1>
      </div>
      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={true}
        style={{ "--swiper-pagination-bullet-inactive-color": "#999999"}}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt="avatar" />
              </div>
              <h3 className="client__name">{name}</h3>
              <div><img src={Rating} alt="rating"></img></div>
              <small className="client__review">
                {review}
                </small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;