import React from 'react'
import "./Footer.css"
import { NavLink } from "react-router-dom";
import { FaFacebookF } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import { FaTiktok } from 'react-icons/fa';
import { useTranslation } from "react-i18next";

const Footer = () => {

  const { t } = useTranslation();

  
  return (
    <footer>
      <ul className='permalinks'>
        <li><a href="#booking">{t("navbar-link-1")}</a></li>
        <li><a href="#membership">{t("navbar-link-2")}</a></li>
        <li><a href="#about">{t("navbar-link-3")}</a></li>
        <li><a href="#contact">{t("navbar-link-4")}</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/kultureathletiquestudio?mibextid=LQQJ4d" target="_blank" rel="noreferrer"><FaFacebookF /></a>
        <a href="https://www.instagram.com/kulture_athletics/" target="_blank" rel="noreferrer">< FiInstagram /></a>
        <a href="https://www.tiktok.com/@boxingfitnessstudio" target="_blank" rel="noreferrer">< FaTiktok /></a>
      </div>
      <div className='footer__legal'>
        <NavLink to="/terms" className='legal__link'>Terms & conditions</NavLink>
        <NavLink to="/privacy" className='legal__link' >Privacy policy</NavLink>
      </div>
      <div className="footer__copyright">
        <small>&copy; Kulture Athlétique {new Date().getFullYear()} {t("copyright")}</small>
      </div>
    </footer>
  )
}

export default Footer