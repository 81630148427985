import React from "react";
import { useTranslation } from "react-i18next";
import LOGO from "../../assets/Images/logo.png";

import "./HeroContainer.css";

const HeroContainer = () => {
    const { t } = useTranslation();


    return (
        <>
            <div className="hero__container flex__center section__padding">
                <div className="hero__background"></div>
                <div className="hero__container-content">
                <img src={LOGO} alt="Logo" />
                    <p>{t("hero-paragraph")}</p>
                    <hr></hr>
                    <p>{t("hero-paragraph2")}</p>
                </div>
            </div>
       
        </>
    );
};

export default HeroContainer;
