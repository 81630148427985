import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";

import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import Footer from "./components/Footer/Footer"
import { Helmet } from "react-helmet";


function App() {

  const [lang, setLang] = useState('fr');
  const metaDescription = lang === 'en' ? "Welcome to Kulture Athlétique, a boxing and bodybuilding training studio founded by Coach Samy and Coach Thomas in 2021.We created our semi-private training studio to offer personalized service to our members, whether in classes in groups or private training you will not be left to yourself.We are a local establishment that tries to make a positive impact in the field of training for people in the neighborhood and we try to get to know our members in order to create a pleasant community environment. We put effort into it while keeping a smile!" : "Bienvenue chez Kulture Athlétique, Studio d'entraînements de boxe et de musculation fondé par Coach Samy et Coach Thomas en 2021.Nous avons créé notre studio d'entraînements semi-privé pour offrir un service personnalisé à nos membres, que ce soit dans les cours en groupes ou entraînements privés vous ne serez pas laissé à vous même. Nous sommes un établissement local qui tente d'avoir un impact positif dans le domaine de l'entraînement pour les gens du quartier et nous essayons de connaître nos membres afin de créer un environnement communautaire plaisant. On y met de l'effort en gardant le sourire!";


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
        <Helmet>
        <meta name="description" content={metaDescription} />
        </Helmet>
        <Navbar />
        <Routes>
          
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
        </Routes>
        <Footer/>
    </>
  );
}

export default App;
