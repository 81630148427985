import React from "react";
import { NavLink } from "react-router-dom";
import "./Privacy.css";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="privacy__container">
        <div className="privacy__header">
          <h1>{t("privacy-title")}</h1>
        </div>
        <div className="privacy__content">
          <p>
          {t("privacy-1")}
            <br />
            {t("privacy-2")}
            <br />
            <br />
            {t("privacy-3")}
            <br />
            {t("privacy-4")}
            <br />
            <br />
            {t("privacy-5")}
            <br />
            <br />
            {t("privacy-6")}
            <br />
            {t("privacy-7")}
            <br />
            <br />
            {t("privacy-8")}
            <br />
            {t("privacy-9")}
            <br />
            {t("privacy-10")} 
            <br/>
            {t("privacy-12")} 
            <br/>
            {t("privacy-13")}
            <br/>
            {t("privacy-14")}
            <br />
            <br />
            {t("privacy-15")}
            <br />
            {t("privacy-16")}
            <br />
            {t("privacy-17")}
            <br />
            <br />
            {t("privacy-18")}
            <br />
            {t("privacy-19")}
            <br />
            <br />
            {t("privacy-20")}
            <br />
            {t("privacy-21")}
            <br />
            <br />
            {t("privacy-22")}
            <br />
            {t("privacy-23")}
            <br />
            {t("privacy-24")}
            <br />
            {t("privacy-25")}
            <br />
            {t("privacy-26")}
             
          <br/>  
          {t("privacy-27")}
            <br />
            {t("privacy-27-2")}
            <br />
            <br />
            {t("privacy-28")}
            <br />
            {t("privacy-29")}
            <br />
            {t("privacy-30")}
            <br />
            {t("privacy-26")}
            <br/>
            {t("privacy-27")}
            <br />
            {t("privacy-27-2")}
            <br />
            <br />
            {t("privacy-32")}
            <br />
            {t("privacy-33")}
            <br />
            {t("privacy-34")}
            <br />
            {t("privacy-35")}
            <br />
            {t("privacy-36")}
            <br />
            {t("privacy-37")}
            <br />
            <br />
            {t("privacy-38")}
            <br />
            {t("privacy-39")}
            <br />
            <br />
            {t("privacy-40")}
            <br />
            {t("privacy-41")}
            <br />
            <br />
            {t("privacy-42")}
            <br />
            {t("privacy-43")}
            <br />
            {t("privacy-44")}
          </p>

          <NavLink to="/" className="return-btn">HOME PAGE</NavLink>
        </div>
      </div>
    </>
  );
};

export default Privacy;
