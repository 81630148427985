import React, { useRef, useEffect } from 'react'
import './LocalisationContainer.css'
import { useTranslation } from "react-i18next";

const LocalisationContainer = () => {

  const { t } = useTranslation();

  const mapContainer = useRef(null);

  useEffect(() => {
    // Initialisez la carte Google Maps
    const myLatLng = {
      lat: 45.53633499145508,
      lng: -73.57096099853516
    };

    const loadScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC8Nr5Hh7Us8KDqkg7dHZjlJvQ99taqtO8&callback=initMap`;
      script.async = true;
      document.head.appendChild(script);
    };

    // Appelez la fonction pour charger l'API
    loadScript();

    // Fonction callback lorsque l'API est chargée
    window.initMap = () => {
      const map = new window.google.maps.Map(mapContainer.current, {
        zoom: 14,
        center: myLatLng,
        fullscreenControl: false,
        zoomControl: true,
        streetViewControl: false
      });

      new window.google.maps.Marker({
        position: myLatLng,
        map,
        title: "My location"
      });
    };
  }, []);


  return (
    <div className='localisation__container flex__center'>
      <div className='localisation__container-content'>
        <h1>{t("localisation-title")}</h1>
        <div className='localisation__grid'>
          <div className='localisation__column'>
            <p><span>{t("localisation-paragraph")}</span> <br></br> 4492 rue Parthenais<br></br> Montréal, QC H2H 2G5</p>
          </div>
          <div className='localisation__column'>
            <div ref={mapContainer} style={{ width: '100%', height: '400px' }}></div>

          </div>

        </div>

      </div>


    </div>

  );

}

export default LocalisationContainer