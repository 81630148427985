import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";

import "./BookingContainer.css";

const BookingContainer = () => {
  const { t } = useTranslation();

  const [scheduleChange, setSchedulechange] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 1020) {
        setSchedulechange(true);
      } else {
        setSchedulechange(false);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const iframeStyle = {
    height: "100%",
    width: "100%",
    border: "none",
  };

  return (
    <>
      <div
        className="booking__container flex__center section__padding"
        id="booking"
      >
        <div className="booking__container-content">
          <h1>{t("booking-title")}</h1>
          <p>{t("booking-paragraph")}</p>
          <Popup
            trigger={
              <a href="#schedule" className="schedule__button">
                {t("booking-btn")}
              </a>
            }
            modal
            nested
          >
            {(close) => (
              <div className="schedule">
                {scheduleChange ? (
                  ""
                ) : (
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                )}
                ;
                <div className="schedule__container flex__center">
                  <iframe
                    src="https://kulturealthletique.fliipapp.com/horaire/c86a7ee3d8ef0b551ed58e354a836f2b/DefaultRoom"
                    title="fliip-integration"
                    style={iframeStyle}
                  ></iframe>
                </div>
                <div className="schedule__links flex__center">
                  {scheduleChange ? (
                    <button onClick={close}>{t("modal-btn-1")}</button>
                  ) : (
                    ""
                  )}
                  ;
                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>
      <hr></hr>
    </>
  );
};

export default BookingContainer;