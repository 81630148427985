import React from "react";
import "./AboutContainer.css";
import IMG1 from "../../assets/Images/Samy.jpg";
import IMG2 from "../../assets/Images/Thomas.jpg";
import { FiInstagram } from "react-icons/fi";
import Fade from "react-reveal/Fade.js";

import { useTranslation } from "react-i18next";

const AboutContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="aboutContainer flex__center " id="about">
        <div className="aboutContainer_content">
          <Fade>
            <div className="about_info">
              <h1>{t("about-title")}</h1>
              <p>
                {t("about-paragraph1")}
                <br/>
                <br />
                {t("about-paragraph1-2")}
                <br />
                <br />
                {t("about-paragraph1-3")}
                <br />
                <br />
              </p>
            </div>
            <hr />
          </Fade>
          <div className="imageContainer">
            <Fade right>
              <div className="samy">
                <img src={IMG1} alt="samy" />
                <p>
                  {t("about-paragraph2")}
                  <br/>
                  {t("about-paragraph2-2")}
                  <br/>
                  {t("about-paragraph2-3")}
                  <br />
                  <br />
                  <a
                    href="https://www.instagram.com/fitness.coach.sb/"
                    target="_blank"
                    rel="noreferrer"
                    className="instagram-link"
                  >
                    <FiInstagram />
                    <span className="instagram-username">fitness.coach.sb</span>
                  </a>
                </p>
              </div>
            </Fade>
            <Fade left>
              <div className="thomas">
                <p>
                  {t("about-paragraph3")}
                  <br/>
                  {t("about-paragraph3-2")}
                  <br/>
                  {t("about-paragraph3-3")}
                  <br />
                  <br />
                  <a
                    href="https://www.instagram.com/thomas_kltr/"
                    target="_blank"
                    rel="noreferrer"
                    className="instagram-link"
                  >
                    <FiInstagram />
                    <span className="instagram-username">thomas_kltr</span>
                  </a>
                </p>
                <img src={IMG2} alt="thomas" />
              </div>
              <div className="thomas-mobile">
                <img src={IMG2} alt="thomas" />
                <p>
                  {t("about-paragraph3")}
                  <br/>
                  {t("about-paragraph3-2")}
                  <br/>
                  {t("about-paragraph3-3")}
                  <br />
                  <br />
                  <a
                    href="https://www.instagram.com/thomas_kltr/"
                    target="_blank"
                    rel="noreferrer"
                    className="instagram-link"
                  >
                    <FiInstagram />
                    <span className="instagram-username">thomas_kltr</span>
                  </a>
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <hr></hr>
    </>
  );
};

export default AboutContainer;
