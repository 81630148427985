import React, { useRef } from 'react'
import "./Contact.css"

import { useTranslation } from "react-i18next";
import emailjs from 'emailjs-com';




const Contact = () => {

  const { t } = useTranslation();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bll6q2s', 'template_7tl6v7d', form.current, 'ZqoIq_rZhvvYK0Bc4')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    e.target.reset();
  };


  return (
    <div id='contact' className='contact__container flex__center'>

      <div className="contact__container-content">
        <h1>{t("contact-title")}</h1>
        <p>{t("contact-paragraph")}</p>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder={t("form-name")} required />
          <input type="email" name='email' placeholder={t("form-mail")} required />
          <textarea name="message" rows="7" placeholder={t("form-message")} required ></textarea>
          <button type='submit' className='btn btn-primary'> {t("form-btn")}</button>
        </form>
        <hr></hr>
      </div>

    </div>
    
  )
}

export default Contact